/**Portfolio-erick - version 6.07 - index css
 *  - Features:
 * 
 *      --> Building media queries.
 *
 *      --> Building styles for contact button and 
  *         pdf icon
 * 
 * Notes: This components is adapted to the new layout
 * and is functional working, pending for layout the 
 * component itself
 */


/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  --max-width: 1170px;
  --fixed-width: 450px;
  --clr-orange-1: hsl(12, 83%, 98%);
  --clr-orange-2: hsl(14, 91%, 95%);
  --clr-orange-3: hsl(12, 89%, 89%);
  --clr-orange-4: hsl(13, 87%, 82%);
  --clr-orange-5: hsl(13, 88%, 68%);
  --clr-orange-6: hsl(13, 88%, 55%);
  --clr-orange-7: hsl(13, 74%, 49%);
  --clr-orange-8: hsl(13, 74%, 33%);
  --clr-orange-9: hsl(13, 73%, 25%);
  --clr-orange-10: hsl(13, 73%, 16%);

  --gradient-bkgd:linear-gradient(
    to left,
    rgb(48, 7, 82) 0%,
    rgba(0, 128, 128, 1) 100%
  );
  --gradient-btn:linear-gradient(
    to left,
    rgb(21, 7, 82) 0%,
    rgb(113, 0, 128) 100%
  );
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../src/assets/subtle-prism.svg);
  background-repeat: repeat;
}

.container{
  display: grid;
  
  grid-template-columns: [container-start] 1fr [center-start] repeat(8, [col-start] minmax(min-content,11rem) [col-end]) [center-end] 1fr [container-end];
  
}


/*Header styles - Global start*/
section#header{
  display: grid;
  margin: 2rem;
  padding: 2rem;
  grid-column: container-start / conatiner-end;
  height: fit-content;
}

section#header .header--logo{
  grid-column: col-start 0 / col-end 1;
}

section#header .header--buttons{
  grid-column: col-start 7 / col-end 8;
}

/*Intro styles - Global start*/
section#intro{
  display: grid;
  grid-column: container-start / container-end;
  place-content: center;
}

.intro--intromessage--title{
  color: yellow;
}

section#intro .intro--intromessage{
  line-height: 2rem;
 
  grid-column: col-start 6 / col-end 8;

  padding: 3rem;
}

section#intro .intro--intromessage--title{
  margin-bottom: 2rem;
}

/*skills styles - Global start*/
section#skills{
  display: grid;
  grid-column: container-start / container-end;
  place-content: center;
  
}


/*modal-container styles - Global start**/
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: var(--transition);
  z-index: -1;
}

.isOpen {
  opacity: 1;
  z-index: 999;
}

.modal-content {
  background: var(--clr-white);
  width: 90vw;
  max-width: var(--fixed-width);
  padding: 3rem;
  border-radius: var(--radius);
  text-align: center;
  position: relative;
}

.contact .close-modal-btn{
  cursor: pointer;
}
  
  /*header--buttons styles**/
  
  .header--buttons{
    display: flex;
    justify-content: flex-end ;
    margin: 2rem;
  }
  
  .header--buttons--each > li{
    list-style-type: none;
  }
  
  .btn{
    border: 1px solid var(--clr-black);
    background: var(--clr-white);
  color: var(--clr-black);
}

.btn:hover {
  background: var(--clr-primary-5);
  color: var(--clr-white);
}

/*footer styles - Global start*/
section#footer{
  display: grid;
  margin: 2rem;
  padding: 2rem;
  grid-template-columns: repeat(2, 1fr); /* divide container into 3 equal columns */
  grid-column: container-start / container-end;
}

.footer--center{
  justify-self: end; /* center horizontally within grid cell */
}

li{
  list-style-type: none;
}

.footer--right {
  justify-self: end;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.footer--right li {
  list-style-type: none;
  margin: 1rem;
  padding: 1rem;
}

.footer--right ul li svg,
.intro--cv a svg{
  font-size: 4rem;
}

.intro--cv {
  margin-top: 3rem;

  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 8px;
  font-size: 17px;
  font-weight: 500;
  color: var(--clr-white);
  text-shadow: none;
  background: transparent;
  box-shadow: transparent;
 
  transition: 0.5s ease;
  user-select: none;
  
  &:hover,:focus {
    color: #ffffff;
    background: #008cff;
    border: 1px solid #008cff;
    text-shadow: 0 0 5px #ffffff,
                0 0 10px #ffffff,
                0 0 20px #ffffff;
    box-shadow: 0 0 5px #008cff,
                0 0 20px #008cff,
                0 0 50px #008cff,
                0 0 100px #008cff;
   cursor: pointer;
  }

}


/*Shared styles*/
section#header,
section#intro,
section#skills,
section#footer{
  background: var(--gradient-bkgd);
  margin: 4rem;
  border-radius: 4rem;
}

p,
h2{
  color: var(--clr-white);
}

.icon{
  color: yellow;  
}

.skills--title{
  display: grid;
  margin: 1rem;
  padding: 1rem;
  text-decoration: underline yellow;
  place-items: center;
}

.skills--title{
  text-transform: capitalize;
}

.skills--box ul{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 2px solid var(--clr-white);
  border-radius: 0.75rem;
  margin: 1rem;
  padding: 1rem;
}

.skills--li-bkg{
  background: var(--gradient-btn);
  margin: 1rem;
  padding: .25rem;
  border-radius: 0.5rem;
}

.header--buttons--each .btn{
  display: grid;
  place-content: center;
  width: 5vw;
  height: 5vh;
  border-radius: 0.7rem;
  cursor: pointer;
}

@media screen and (max-width:2000px){
  section#intro .intro--intromessage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50rem;
  }
}

@media screen and (max-width:900px){
  section#intro .intro--intromessage {
    width: 27rem;
  }
}

@media screen and (max-width:520px){
  
  section#header{
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header--logo{
    display: grid;
    place-content: center;
  }

  .skills--box ul {
    display: flex;
    flex-direction: column;
    margin: 2rem;
    padding: 2rem;
  }

  .skills--title{
    margin: 1rem;
    padding: 1rem;
  }

  .skills--box ul li{
    width: fit-content;
  }

  section#footer{
    display: flex;
    flex-direction: column;
  }

  section#footer ul{
    margin: 1rem;
    padding: 1rem;
  }

  .footer--right{
    display: flex;
    flex-direction: column;
  }

  section#intro .intro--intromessage {
    width: 20rem;
  }
  
}




